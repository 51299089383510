.headerBg {
    background: #111111;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* если форму поиска оставлять, то:
    padding: 25px 0; */
    padding: 35px 0;
}

.brandContainer {}

.brandText {
    font-weight: 300;
    font-size: 24px;
    color: #FEFEFE;
}

.brandText:hover {
    color: #FEFEFE;

}

.searchForm {
    position: relative;
    display: none;
}

.searchImg {
    position: absolute;
    left: 30px;
    top: 29%;

}

.searchInput {
    width: 420px;
    height: 50px;
    padding: 14px 14px 14px 68px;
    background: #1B1919;
    border-radius: 30px;
    font-size: 18px;
    color: #FEFEFE;
}

.searchInput::placeholder {
    font-size: 18px;
    color: #5F5F5F;
}



/*===================== main =========================*/

.mainBg {
    position: relative;
    background-color: black;
    height: 810px;
}

.mainBgImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.mainOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding-top: 120px;
    padding-bottom: 100px;
    background: linear-gradient(90.3deg, #111111 19%, rgba(17, 17, 17, 0) 99.75%);
}

.topContainer {
    height: 434px;
}

.filmTitle {
    width: 566px;
    font-weight: 900;
    font-size: 72px;
    line-height: 1.22;
    color: #FBFBFB;
    margin-bottom: 30px;
}

.infoBox {
    display: flex;

}

.ratingBlockMain {
    margin-right: 24px;
}

.infoBox span {
    position: relative;
    padding-right: 24px;
    font-weight: 600;
    font-size: 24px;
    color: #FBFBFB;
    margin-right: 24px;
}

.infoBox span:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 24px;
    background-color: #FEFEFE;
    ;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

}

.infoBox span:last-child:after {
    display: none;
}

.genre {}

.type {}

.year {}

.btnTrailer {
    width: 199px;
    height: 64px;
    border: 2px solid #FBFBFB;
    border-radius: 40px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    color: #FBFBFB;
    transition: background-color .3s ease-in,
        color .3s ease-in;
    margin-bottom: 50px;
}

.btnTrailer:hover {
    background-color: #fff;
    color: black;
}

.awards {
    width: 433px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.22;
    color: #FBFBFB;
}

.awardsMt {
    margin-top: 114px;
}

/* =============== plot =========== */

.plotBg {
    background-color: #fff;
}

.plot {
    padding-top: 60px;
    padding-bottom: 30px;
}

.plotHeading {
    width: 800px;
    font-weight: 900;
    font-size: 36px;
    line-height: 1.44;
    color: #323232;
    margin-bottom: 30px;

}

.plotText {
    width: 800px;
    font-size: 18px;
    line-height: 1.56;
    color: #323232;
}

/*======================= footer===================== */

.footerBg {
    background-color: #111111;
}

.footer {
    padding: 39px 0;
}

.brandTextFooter {
    text-align: center;
    font-weight: 900;
    font-size: 18px;
    line-height: 1.22;
    color: #FEFEFE;
}

/*======================= modal ===================== */
.modalWrapper {
    padding: 65px;
    background-color: rgb(24, 24, 24);
    /* background-color: rgb(8, 44, 75); */
    border-radius: 13px;

}

.trailerIframe {
    width: 1100px;
    height: 700px;
}

@media (max-width: 1320px) {

    .modalWrapper {
        padding: 48px;
    }

    .trailerIframe {
        width: 629px;
    }

}


@media (max-width: 767px) {
    .modalWrapper {
        padding: 52px 15px;
    }

    .trailerIframe {
        width: 311px;
        height: 330px;
    }

}

@media (max-width: 359px) {
    .trailerIframe {
        width: 273px;
        height: 277px;
    }
}
