.bg {
    position: fixed;
    z-index: -2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgb(68, 68, 68);
    overflow: hidden;
}

.videoBgLauout {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.445);
}

.videoBg {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transform: scale(1.4);
}

.search {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 200px;
    margin-bottom: 20px;
}

.heading {
    max-width: 737px;
    font-family: Montserrat, sans-serif;
    font-size: 64px;
    font-weight: 900;
    line-height: 1.2;
    text-align: center;
    color: #FEFEFE;
    margin-bottom: 40px;
}


.subHeading {
    font-family: Montserrat, sans-serif;
    ;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    color: #FEFEFE;
    margin-bottom: 80px;
}

.form {
    display: flex;
}

.input {
    height: 50px;
    width: 429px;
    padding: 14px 28px;
    border-radius: 30px 0 0 30px;
    background-color: #fff;
    font-size: 18px;
}

.input::placeholder {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    color: #CCCCCC;
}

.formButton {
    width: 121px;
    height: 50px;
    background-color: #4EA7F9;
    border-radius: 0px 30px 30px 0px;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #FEFEFE;
    transition: background-color .25s ease-in;
}

.formButton:hover {
    background-color: #188dfb;
}

@media(max-width: 1200px) {
    .heading {
        font-size: 57px;
        margin-bottom: 25px;
    }

    .search {
        padding-top: 150px;
    }

    .subHeading {
        font-size: 31px;
        margin-bottom: 62px;
    }


}

@media(max-width: 992px) {}

@media(max-width: 767px) {

    .heading {
        font-size: 30px;
        margin-bottom: 13px;
    }

    .search {
        padding-top: 50px;
    }

    .subHeading {
        font-size: 21px;
    }

    .input {
        width: 251px;
        font-size: 17px;
    }


    .input::placeholder {
        font-size: 17px;
    }

    .formButton {
        width: 78px;
        font-size: 16px;
    }




}

@media(max-width: 359px) {}