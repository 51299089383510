
.similarItem {
    position: relative;
    width: 263px;
    height: 361px;
    border-radius: 15px;
    overflow: hidden;
    margin-right: 30px;
}

.similarItem:last-child {
    margin-right: 0;
}

.similarItem__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.similarItem__layout {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 36px 30px 40px 30px;
    background-color: #494949bb;
    background: linear-gradient(0deg, rgba(20, 19, 19, 0.85), rgba(20, 19, 19, 0.85));
    opacity: 0;
    transition: opacity 0.3s ease-in;
}

.similarItem__layout:hover {
    opacity: 1;
}

.similarItem__title {
    width: 100%;
    height: 58px;
    font-weight: 800;
    font-size: 24px;
    line-height: 1.2;
    color: #FFFFFF;
    margin-bottom: 8px;
}

.similarItem__genre {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    color: #FFFFFF;
    margin-bottom: 8px;
}

.similarItem__type {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    color: #FFFFFF;
}

.similarItem__year {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.similarItem__plot {
    height: 108px;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.29;
    color: #FFFFFF;
    margin-top: 21px;
    margin-bottom: 16px;
}