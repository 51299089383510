body {
   font-family: Montserrat, sans-serif;
}


.container {
   max-width: 1140px;
   margin-right: auto;
   margin-left: auto;
   padding: 0 15px;
}

@media(max-width: 1200px) {
   .container {
      max-width: 970px;
   }

}

@media(max-width: 992px) {
   .container {
      max-width: 750px;
   }

}

@media(max-width: 767px) {
   .container {
      max-width: none;
   }

}


.ratingBlock {
   flex-shrink: 0;
   padding: 7px 12px 8px;
   width: 87px;
   height: 30px;
   background: #FAC539;
   border-radius: 8px;
   font-size: 12px;
   font-weight: 900;
}